<template>
  <div>
    <v-dialog v-model="dialog" width="1000" class="pa-4" persistent>
      <v-card class="pa-5">
        <v-card-title><h2 class="">Update Unit</h2></v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-row>
              <v-col>
                <v-select
                  :items="unit_type"
                  label="Unit Type"
                  v-model="unit.unit_type"
                  :rules="[(v) => !!v || 'Item is required']"
                ></v-select>
                <v-text-field
                  label="Unit Brand"
                  v-model="unit.unit_brand"
                  :rules="[(v) => !!v || 'Item is required']"
                ></v-text-field>
                <v-text-field
                  label="Unit Model"
                  v-model="unit.unit_model"
                  :rules="[(v) => !!v || 'Item is required']"
                ></v-text-field>

                <v-text-field
                  label="Serial Number"
                  v-model="unit.serial_no"
                  :rules="[(v) => !!v || 'Item is required']"
                ></v-text-field>
              </v-col>
              <v-col>
                <p>Date Recieved</p>
                <input type="date" v-model="unit.date_received" />
                <v-checkbox
                  @click="update_pick_up"
                  label="Picked up by owner"
                ></v-checkbox>
                <v-text-field
                  label="Picked up by"
                  v-model="unit.picked_up_by"
                ></v-text-field>

                <p>Picked Up Date</p>
                <input type="date" v-model="unit.picked_up_date" />
              </v-col> </v-row
          ></v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="updateUnit"
            class=""
            :loading="loading"
            color="primary"
            text
            >Update</v-btn
          >
          <v-btn text @click="$emit('close')" class="ml-2">Close</v-btn>
          <v-spacer></v-spacer>
          <p class="caption grey--text">updateUnitModal.vue</p>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  components: {},
  props: ["unit"],
  data() {
    return {
      dialog: true,
      loading: false,
      unit_type: ["Laptop", "Printer", "Desktop PC", "Others"],
    };
  },
  methods: {
    update_pick_up() {
      this.unit.picked_up_by = this.unit.customer.customer_name;
    },
    ...mapActions({
      update_unit: "unit/update_unit",
      set_notification: "notification/set_notification",
    }),
    async updateUnit() {
      this.loading = true;
      let x = window.confirm("Are you sure you want to proceed?");
      if (x) {
        await this.update_unit(this.unit);
        this.set_notification({
          message: "You have successfully updated a unit!",
          type: "success",
        });
      }
      this.loading = false;
    },
  },
  created() {
    // this.unit.picked_up_date = moment().format("Y-MM-D");
    console.log(this.unit);
  },
};
</script>

<style lang="scss" scoped></style>
